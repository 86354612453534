<template>
  <div v-if="offlineConfirmed">
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
        <div class="container flex flex-col items-center justify-center">
          <h1 :class="$style.title">
            The page timed out while loading. Are you sure you're still connected to the Internet?
          </h1>
          <BaseLink tag='a' :to="{ name: 'home' }" class="px-8 py-3 font-semibold rounded bg-theme-orange text-white mt-3">Back to homepage</BaseLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  page: {
    title: 'Request timed out...',
    meta: [
      { name: 'description', content: 'The request timed out while loading.' },
    ],
  },
  data() {
    return {
      offlineConfirmed: true,
    }
  },
}
</script>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
