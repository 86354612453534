import { mapState, mapGetters, mapActions } from 'vuex';

/* auth state helpers ------------------------------------------------------- */
export const authComputed = {
  ...mapState('auth', {
    loadingValidateUser: (state) => state.loadingValidateUser,
    token: (state) => state.token,
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', [
    'loggedIn',
    'token',
    'isLoadingValidateUser'
  ]),
};
export const authMethods = mapActions('auth', [
  'logIn',
  'logOut',
  'validateUser',
  'register',
  'forgotPassword',
  'resetPassword',
  'updatePassword',
]);

/* user state helpers ------------------------------------------------------- */
export const usersComputed = {
  ...mapState('users', {
    countries: (state) => state.countries,
    bookingListLoading: (state) => state.bookingListLoading,
    bookingList: (state) => state.bookingList,
    bookingHistoryLoading: (state) => state.bookingHistoryLoading,
    bookingHistory: (state) => state.bookingHistory,
    bookingDetailLoading: (state) => state.bookingDetailLoading,
    bookingDetail: (state) => state.bookingDetail,
  }),
  ...mapGetters('users', ['fethedCountries']),
};
export const usersMethods = mapActions('users', [
  'fetchCountries',
  'fetchBookingList',
  'fetchBookingHistory',
  'fetchBookingDetail',
  'cancelBooking',
  'updateProfile',
]);

/* latr state helpers ------------------------------------------------------- */
export const latrComputed = {
  ...mapState('latr', {
    coachLoading: (state) => state.coachLoading,
    coach: (state) => state.coach,
    webContentLoading: (state) => state.webContentLoading,
    webContent: (state) => state.webContent,
    clubs: (state) => state.clubs,
    trainingBase: (state) => state.trainingBase,
    socialMedia: (state) => state.socialMedia,
    taxSetting: (state) => state.taxSetting,
    perPageServices: (state) => state.perPageServices,
    perPageClubs: (state) => state.perPageClubs,
    perPageSuccessStories: (state) => state.perPageSuccessStories,
    bookingFormData: (state) => state.bookingFormData,
    serviceDetailLoading: (state) => state.serviceDetailLoading,
    serviceDetail: (state) => state.serviceDetail,
    instagramFeedLoading: (state) => state.instagramFeedLoading,
    instagramFeed: (state) => state.instagramFeed,
  }),
  ...mapGetters('latr', [
    'savedServiceDetail',
    'savedBookingFormData',
    'savedTaxSetting',
    'savedTrainingBase',
    'savedClubs',
    'savedWebContent',
    'savedCoach',
    'savedInstagramFeed',
  ]),
};
export const latrMethods = mapActions('latr', [
  'fetchInstagramFeed',
  'fetchCoach',
  'fetchWebContent',
  'fetchClubs',
  'fetchTrainingBase',
  'fetchSocialMedia',
  'fetchSuccessStories',
  'fetchTaxSetting',
  'fetchServices',
  'fetchServicesDetail',
  'fetchSlotAvailability',
  'fetchPaymentIntent',
  'setBookingFormData',
  'setDefaultBookingFormData',
  'postBooking',
  'makePayment',
  'postContact',
]);
