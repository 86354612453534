import Vue from 'vue'
import router from '@router'
import store from '@state/store'
import directives from '@directives'
import App from './app.vue'
import {
  chain, map, filter, each, values, size
} from 'lodash/core'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'
import moment from 'moment'

// global filters
import * as filters from '@filters';

// Styles
import 'floating-vue/dist/style.css'
import '@design/index.scss'
import '@design/icons.scss'

// Libs
import '@libs/events'
import '@libs/toastification'
import '@libs/calendar'
import '@libs/lazyload'
import '@libs/floating-vue'

// Globally register all `_base`-prefixed components
import '@components/_globals'

// Directives
Object.keys(directives).forEach(name => Vue.directive(name, directives[name]))

/**
 * If it doesn't already exist, register a separate empty vue instance that
 * is attached to the window, we can use it to listen out for and handle
 * any events that may emitted by components...
 */
if (!window.eventHub) {
  window.eventHub = new Vue()
}

// register global utility filters.
for (const key of Object.keys(filters)) {
  Vue.filter(key, filters[key]);
}

Vue.prototype.$moment = moment
Vue.prototype.$_map = map
Vue.prototype.$_each = each
Vue.prototype.$_filter = filter
Vue.prototype.$_remove = remove
Vue.prototype.$_chain = chain
Vue.prototype.$_values = values
Vue.prototype.$_size = size
Vue.prototype.$_cloneDeep = cloneDeep
Vue.prototype.$_findIndex = findIndex

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
