<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
    <template v-for="(val, key) in values">
      <button :key="key" type="button" @click.prevent="changeSelectVal(val)" :class="['btn btn-slot', { 'selected': val.is_selected, 'disabled': val.is_disabled }]">{{ val.formatted_slot }}</button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      required: false
    },
    default: {
      required: false
    },
    selected: {
      required: false
    }
  },
  methods: {
    changeSelectVal: function(val) {
      const index = this.$_findIndex(this.values, { day: val.day, slot: val.slot })
      this.values[index].is_selected = !this.values[index].is_selected;

      this.$emit('selected', this.values[index]);
    }
  }
};
</script>
