import axiosIns from '@libs/axios'
import { toCurrency } from '@utils/format-string'
import { saveState, getSavedState } from '@utils/local-storage'

/* state -------------------------------------------------------------------- */
export const state = {
  perPageServices: 6,
  perPageClubs: 100,
  perPageSuccessStories: 10,
  perPageCoach: 100,
  bookingFormData: getSavedState('booking.formData'),
  serviceDetailLoading: false,
  serviceDetail: getSavedState('booking.serviceDetail'),
  taxSetting: getSavedState('booking.taxSetting'),
  trainingBase: getSavedState('booking.trainingBase'),
  clubs: [],
  coach: [],
  coachLoading: false,
  webContentLoading: false,
  webContent: null,
  socialMedia: getSavedState('booking.socialMedia') || [],
  instagramFeedLoading: false,
  instagramFeed: [],
}

/* getters ------------------------------------------------------------------ */
export const getters = {
  savedServiceDetail(state) {
    return state.serviceDetail
  },
  savedBookingFormData() {
    return state.bookingFormData
  },
  savedTaxSetting() {
    return state.taxSetting
  },
  savedTrainingBase() {
    return state.trainingBase
  },
  savedClubs() {
    return state.clubs
  },
  savedSocialMedia() {
    return state.socialMedia
  },
  savedSuccessStories() {
    return state.successStories
  },
  savedWebContent() {
    return state.webContent
  },
  savedCoach() {
    return state.coach
  },
  savedInstagramFeed() {
    return state.instagramFeed
  },
}

/* mutations ---------------------------------------------------------------- */
export const mutations = {
  SET_TAX_SETTING(state, data) {
    state.taxSetting = data
    saveState('booking.taxSetting', data)
  },
  SET_TRAINING_BASE(state, data) {
    state.trainingBase = data
    saveState('booking.trainingBase', data)
  },
  SET_CLUBS(state, data) {
    state.clubs = data
  },
  SET_WEB_CONTENT_LOADING(state, data) {
    state.webContentLoading = data
  },
  SET_WEB_CONTENT(state, data) {
    state.webContent = data
  },
  SET_IG_FEED_LOADING(state, data) {
    state.instagramFeedLoading = data
  },
  SET_IG_FEED(state, data) {
    state.instagramFeed = data
  },
  SET_COACH_LOADING(state, data) {
    state.coachLoading = data
  },
  SET_COACH(state, data) {
    state.coach = data
  },
  SET_SOCIAL_MEDIA(state, data) {
    state.socialMedia = data
    saveState('booking.socialMedia', data)
  },
  SET_SUCCESS_STORIES(state, data) {
    state.successStories = data
  },
  SET_SERVICE_DETAIL_LOADING(state, data) {
    state.serviceDetailLoading = data
  },
  SET_SERVICE_DETAIL(state, data) {
    state.serviceDetail = data
    saveState('booking.serviceDetail', data)
  },
  SET_BOOKING_FORMDATA(state, data) {
    state.bookingFormData = data
    saveState('booking.formData', data)
  },
}

/* actions ------------------------------------------------------------------ */
export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    // console.log('init latr store module');
    const { user_init } = state.bookingFormData || { user_init: false }
    if (!user_init) {
      dispatch('setDefaultBookingFormData')
    }
    dispatch('fetchWebContent')
    dispatch('fetchTaxSetting')
    dispatch('fetchTrainingBase')
  },

  setDefaultBookingFormData({ commit, dispatch, state }) {
    // console.log('setDefaultBookingFormData');
    commit('SET_BOOKING_FORMDATA', {
      selected_service: null,
      edit_mode: false,
      edit_date: null,
      code: null,
      user_init: false,
      user_id: null,
      original_amount: 0,
      original_amount_formatted: "£0.00",
      tax_id: null,
      tax_percent: null,
      tax_amount: null,
      tax_amount_formatted: "£0.00",
      discount: null,
      discount_formatted: null,
      other_fees: null,
      other_fees_formatted: null,
      grand_total: null,
      grand_total_formatted: "£0.00",
      total_ticket: 0,
      services: [],
    })
    commit('SET_SERVICE_DETAIL', null)
  },

  setBookingFormData({ commit, dispatch, state }, args) {
    // console.log('setBookingFormData');
    const vm = this._vm
    let grandTotal = 0
    let grandTotalTicket = 0
    // const services = args.services
    vm.$_map(args.services, (service, serviceKey) => {
      let subTotal = 0
      let subTotalTicket = 0
      vm.$_map(service.schedules, (schedule, scheduleKey) => {
        let totalBlock = schedule.slots ? schedule.slots.length : 0
        let totalPerson = schedule.person || 0

        let totalTicket = totalBlock * totalPerson
        let subTotalPerBlock = service.price * totalTicket

        schedule.sub_total = subTotalPerBlock
        schedule.sub_total_formatted = toCurrency(subTotalPerBlock)
        subTotal += subTotalPerBlock
        subTotalTicket += totalTicket
        // console.log(`subTotalPerBlock dengan service id: ${service.price} dan schedule id: ${schedule.id} | ${schedule.date}`, subTotalPerBlock);

        return schedule
      })
      grandTotal += subTotal
      grandTotalTicket += subTotalTicket

      return service
    })

    const taxAmount = ((grandTotal * args.tax_percent) / 100)
    const originalAmount = grandTotal
    const finalGrandTotal = grandTotal + taxAmount

    args.tax_amount = taxAmount
    args.tax_amount_formatted = toCurrency(taxAmount)

    args.original_amount = originalAmount
    args.original_amount_formatted = toCurrency(originalAmount)

    args.grand_total = finalGrandTotal
    args.grand_total_formatted = toCurrency(finalGrandTotal)

    args.total_ticket = grandTotalTicket

    commit('SET_BOOKING_FORMDATA', args)
  },

  fetchServices({ commit, state }, args) {
    return axiosIns.post('/publicData/service', {
      data: {
        requireTotalCount: true,
        skip: args.skip || 0,
        take: args.take || state.perPageServices,
        orderBy: "services.id",
        select: [
          "services.id",
          "services.category",
          "services.location",
          "services.name",
          "services.description",
          "services.duration",
          "services.price",
          "services.discount",
          "services.image",
          "services.coach_id",
          "services.created_at",
          "services.updated_at"
        ],
        orderBy: "services.id DESC",
        join: [
          {
            "on": [
              "coach.id",
              "=",
              "services.coach_id"
            ],
            "type": "join",
            "table": "coach",
            "clousure": {
              "where": [
                "coach.deleted_at",
                "isnull",
                null
              ]
            }
          }
        ],
        addSelect: [
          "CONCAT(coach.firstname, ' ', coach.lastname) AS coach_fullname"
        ],
        with: [
          "coach:id,user_id,firstname,lastname,division,profile_pic,licence"
        ]
      }
    }).then((response) => {
      const { success, data, message } = response.data
      if (success) {
        return data
      }

      return null
    })
  },

  fetchServicesDetail({ commit, state }, args) {
    commit('SET_SERVICE_DETAIL_LOADING', true)
    return axiosIns.get(`/service/${args.serviceId}/detail`).then((response) => {
      const { status, data } = response

      if (status === 200) {
        commit('SET_SERVICE_DETAIL', data)
        commit('SET_SERVICE_DETAIL_LOADING', false)

        return data
      }

      commit('SET_SERVICE_DETAIL_LOADING', false)
      return null
    })
  },

  fetchTaxSetting({ commit, state }) {
    const taxSetting = state.taxSetting
    if (taxSetting) return Promise.resolve(taxSetting)

    return axiosIns.get('getTaxSetting').then((response) => {
      const { status, data } = response

      if (status === 200) {
        commit('SET_TAX_SETTING', data)

        return data
      }

      return null
    })
  },

  fetchTrainingBase({ commit, state }) {
    const trainingBase = state.trainingBase
    if (trainingBase) return Promise.resolve(trainingBase)

    return axiosIns.get('getTrainingBase').then((response) => {
      const { status, data } = response

      if (status === 200) {
        commit('SET_TRAINING_BASE', data)

        return data
      }

      return null
    })
  },

  fetchClubs({ commit, state }, args) {
    const clubs = state.clubs
    if (clubs.length > 0) return Promise.resolve(clubs)

    return axiosIns.post('/publicData/club', {
      data: {
        requireTotalCount: true,
        // skip: args.skip || 0,
        // take: args.take || state.perPageServices,
        orderBy: "master_club.order",
      }
    }).then((response) => {
      const { success, data } = response.data

      if (success) {
        commit('SET_CLUBS', data)

        return data
      }

      return null
    })
  },

  fetchSocialMedia({ commit, state }, args) {
    const socialMedia = state.socialMedia
    if (socialMedia.length > 0) return Promise.resolve(socialMedia)

    return axiosIns.post('/publicData/organization', {
      data: {
        requireTotalCount: true,
        // skip: args.skip || 0,
        // take: args.take || state.perPageServices,
        with: [
          "socialMedia"
        ]
      }
    }).then((response) => {
      const { success, data } = response.data

      if (success) {
        commit('SET_SOCIAL_MEDIA', data)

        return data
      }

      return null
    })
  },

  fetchSuccessStories({ commit, state }, args) {
    return axiosIns.post('/publicData/successStories', {
      data: {
        requireTotalCount: true,
        skip: args.skip || 0,
        take: args.take || state.perPageSuccessStories,
        orderBy: "success_stories.order",
        select: [
          "success_stories.id",
          "success_stories.club_id",
          "success_stories.name",
          "success_stories.photo",
          "success_stories.order",
          "success_stories.description"
        ],
        with: [
          "club:id,name,logo"
        ]
      }
    }).then((response) => {
      const { success, data } = response.data

      if (success) {
        return data
      }

      return null
    })
  },

  fetchSlotAvailability({ commit, state }, args) {
    return axiosIns.get(`/service/${args.serviceId}/slotAvailability`, {
      params: args.params
    }).then((response) => {
      const { status, data } = response
      if (status === 200) {
        return data
      }

      return null
    })
  },

  fetchPaymentIntent({ commit, state }, ...args) {
    return axiosIns.post('/stripe/paymentSheet', ...args).then((response) => {
      const { success, data } = response.data
      if (success) {
        return data
      }

      return null
    })
  },

  fetchWebContent({ commit, state }) {
    const webContent = state.webContent
    if (webContent) return Promise.resolve(webContent)

    commit('SET_WEB_CONTENT_LOADING', true)
    return axiosIns.get('/getWebContent').then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_WEB_CONTENT', data)
        commit('SET_WEB_CONTENT_LOADING', false)
        return data
      }

      commit('SET_WEB_CONTENT_LOADING', false)
      return null
    })
  },

  fetchCoach({ commit, state }) {
    const coach = state.coach
    if (coach.length > 0) return Promise.resolve(coach)

    commit('SET_COACH_LOADING', true)
    return axiosIns.post('/publicData/coach', {
      data: {
        requireTotalCount: true,
        skip: 0,
        take: state.perPageCoach,
        select: [
          "coach.id",
          "coach.user_id",
          "coach.firstname",
          "coach.lastname",
          "coach.licence",
          "coach.description",
        ],
        orderBy: "coach.order",
        addSelect: [
          "CONCAT(coach.firstname, ' ', coach.lastname) AS fullname",
          "CONCAT(coach.firstname, ' ', coach.lastname) AS title"
        ]
      }
    }).then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_COACH', data.data)
        commit('SET_COACH_LOADING', false)
        return data.data
      }

      commit('SET_COACH_LOADING', false)
      return null
    })
  },

  fetchInstagramFeed({ commit, state }, ...args) {
    const vm = this._vm
    const instagramFeed = state.instagramFeed
    if (instagramFeed.length > 0) return Promise.resolve(instagramFeed)

    commit('SET_IG_FEED_LOADING', true)
    const params = new URLSearchParams(...args)

    return axiosIns.get(`/instagram/feed?${params}`).then((response) => {
      const { success, data } = response.data
      const instagramFeedItems = []
      if (success) {
        vm.$_each(data.instagram_feed, (data, i) => {
          if (vm.$_size(data.children) > 0) {
            vm.$_each(data.children, (children, j) => {
              instagramFeedItems.push({
                title: 'LATR & Levels FC',
                description: data.caption,
                thumb: children.url,
                src: children.url,
              })
            })
          } else {
            instagramFeedItems.push({
              title: 'LATR & Levels FC',
              description: data.caption,
              thumb: data.thumbnail_url,
              src: data.url,
            })
          }
        })
        commit('SET_IG_FEED', instagramFeedItems)
        commit('SET_IG_FEED_LOADING', false)
        return data.data
      }

      commit('SET_IG_FEED_LOADING', false)
      return null
    })
  },

  postBooking({ commit, state }, ...args) {
    return axiosIns.post('/booking', ...args).then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_BOOKING_FORMDATA', data)
        return data
      }

      return null
    })
  },

  makePayment({ commit, state }, ...args) {
    return axiosIns.post('/makePayment', ...args).then((response) => {
      const { success, data } = response.data
      if (success) {
        return data
      }

      return null
    })
  },

  postContact({commit, state, rootState}, ...args) {
    return axiosIns.post('sendContact', ...args).then((response) => {
      const { success, data } = response.data
      return success
    }).catch((error) => {
      console.warn(error)
      return false
    });
  }
}
