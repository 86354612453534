<template>
  <div class="">
    <section v-if="trainingBase" class="relative overflow-hidden">
      <div class="container-fluid relative">
          <div class="grid grid-cols-1">
            <div class="w-full leading-[0] border-0">
              <iframe :src="`https://maps.google.com/maps?q=${trainingBase.lat},${trainingBase.lng}&hl=en&z=14&amp;output=embed`" style="border:0" class="w-full h-[500px]" allowfullscreen></iframe>
            </div>
          </div>
          <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <section class="md:py-24 py-16 pb-6 md:pb-6">
      <div class="container">
        <div class="grid grid-cols-1 gap-4 text-center">
          <span class=" bg-theme-orange/20 text-theme-orange text-lg font-medium px-3 py-1.5 rounded place-self-center">Social Media</span>
          <h3 class="md:text-3xl md:leading-normal text-3xl leading-normal font-semibold">Keep in Touch!</h3>
          <p class="text-lg text-[#040707] max-w-xl mx-auto">Follow our social media to keep updated about our events and promotions.</p>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->

      <div class="container md:pt-12 pt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 gap-10 items-center">
          <div v-for="(organization, indexOrganization) in socialMedia" :key="indexOrganization" class="flex flex-col">
            <div class="flex flex-row items-center mb-4">
              <div class="w-7 mr-2">
                <img v-lazy="organization.logo_url" :alt="organization.name" class="lazy-img-fadein lazy-image" />
              </div>
              <h1 class="text-base font-semibold">{{ organization.name || '' }}</h1>
            </div>

            <div class="flex flex-row flex-wrap items-center">
              <div v-for="(socmed, indexSocmed) in organization.social_media" :key="indexSocmed" class="flex flex-row items-center mr-3 mb-4">
                <div class="w-6 mr-2">
                  <img v-lazy="socmed.logo_url" :alt="socmed.name" class="lazy-img-fadein lazy-image ml-1" />
                </div>
                <a :href="socmed.link" target="_blank">
                  <h1 class="text-sm font-semibold hover:text-theme-orange transition-all duration-500 ease-in-out">{{ socmed.name || '' }}</h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->

    <div class="pb-12">
      <div @mousedown.left="onMouseDown">
        <vue-horizontal class="horizontal" ref="horizontal" snap="none" @scroll="onScroll" :displacement="0.7">
          <template v-if="instagramFeedLoading">
            <div v-for="i in 8" :key="i" class="w-[180px] mx-auto mr-5">
              <BaseShimmerBlock width="180px" height="180px" />
            </div>
          </template>
          <template v-else>
            <div v-for="(image, imageIndex) in instagramFeed" :key="imageIndex" class="relative overflow-hidden rounded-md cursor-pointer select-none mr-5">
              <img v-lazy="image.thumb" :alt="image.title" class="h-[180px] w-full object-cover object-center lazy-img-fadein lazy-image" @click.prevent="instagramFeedIndex = imageIndex" :draggable="false" />
            </div>
          </template>
        </vue-horizontal>
      </div>
    </div>

    <CoolLightBox
      :fullScreen="true"
      :items="instagramFeed"
      :index="instagramFeedIndex"
      @close="instagramFeedIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import { latrComputed, latrMethods } from '@state/helpers'
import { vueHorizontal } from '@mixins'

import VueHorizontal from 'vue-horizontal';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  data() {
    return {
      instagramFeedIndex: null
    }
  },
  components: {
    CoolLightBox,
    VueHorizontal,
  },
  mixins: [
    vueHorizontal
  ],
  computed: {
    ...latrComputed,
  },
  created() {
    // fetch instagram feed
    this.fetchInstagramFeed({
      username: 'levelsfc',
      refreshFeed: 'false'
    })
  },
  methods: {
    ...latrMethods,
  }
}
</script>
