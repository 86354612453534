<template>
  <div id="app">
    <component :is="LayoutComponent" :key="LayoutComponent.name || LayoutComponent.__file">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
    </component>
  </div>
</template>

<script>
import appConfig from '@src/app.config'
import ToastificationContent from '@components/plugins/toastification-content.vue'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  computed: {
    LayoutComponent() {
      return (
        (this.$route.meta && this.$route.meta.layout) || require('@layouts/main.vue').default
      )
    }
  },
  events: {
    showNotif(payloads) {
      this.$toast.clear();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: payloads.title !== undefined && payloads.title !== '' ? payloads.title : 'Process Failed',
          text: payloads.msg !== undefined ? payloads.msg : '',
          icon: payloads.icon !== undefined ? payloads.icon : 'BellIcon',
          variant: payloads.variant !== undefined ? payloads.variant : 'success'
        }
      });
    }
  }
}
</script>

<style lang="scss">
// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

#nprogress .bar {
  @apply bg-theme-orange;
}
#nprogress .spinner-icon {
  @apply border-t-theme-orange border-l-theme-orange;
}
</style>
