<template>
  <div id="preloader">
    <div id="status">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  page: {
    title: 'Loading...',
    meta: [{ name: 'description', content: 'Loading...' }],
  },
}
</script>
