<template>
  <div class="relative">
    <NavBar />
    <slot />
    <Footer />
  </div>
</template>

<script>
import NavBar from '@components/layouts/nav-bar.vue'
import Footer from '@components/layouts/footer.vue'

export default {
  components: { NavBar, Footer },
}
</script>
