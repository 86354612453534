<template>
  <div>
    <p v-for="(lines, index) in paragraphs" v-bind:key="index">
      <BaseShimmerSentences :lines="lines"></BaseShimmerSentences>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 5
    }
  },
  data () {
    return {
      paragraphs: []
    }
  },
  mounted () {
    this.setParagraphs()
  },
  methods: {
    setParagraphs () {
      const paragraphs = []
      const lines = [
        4,
        2,
        3,
        4,
        2
      ]
      let lineIndex = 0
      for (let i = 0; i < this.total; i++) {
        paragraphs.push(lines[lineIndex])
        lineIndex = lineIndex >= (lines.length - 1) ? 0 : lineIndex + 1
      }
      this.paragraphs = paragraphs
    }
  }
}
</script>
