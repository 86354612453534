<template>
  <div class="toastification">
    <div class="flex items-start">
      <div class="avatar placeholder mr-2">
        <div class="text-white rounded-full w-8" :class="`bg-${variant}`">
          <span class="text-xs"><feather-icon :icon="icon" size="15" /></span>
        </div>
      </div>
      <div class="flex grow">
        <div>
          <h5 v-if="title" v-text="title" class="toastification-title font-bold uppercase leading-none" :class="`text-${variant}`"/>
          <small v-if="text" class="text-dark leading-none" v-text="text" />
        </div>
        <span class="toastification-close-icon cursor-pointer ml-auto" @click.prevent="$emit('close-toast')">
          <feather-icon v-if="!hideClose" icon="XIcon" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
