import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

// or with options
const loadimage = require('@assets/images/loading-bg.svg')
const errorimage = require('@assets/images/404.png')

Vue.use(VueLazyload, {
  lazyComponent: true,
  lazyImage: true,
  error: errorimage,
  loading: loadimage,
})
