<template>
  <div style="display: block">
    <div class="shimmer shimmer-block" :style="styles" v-bind="$attrs"></div>
  </div>
</template>

<script>
import { dimensions } from '@mixins'

export default {
  mixins: [
    dimensions
  ],
  computed: {
    styles () {
      return {
        width: '100%',
        maxWidth: this.width,
        height: this.height
      }
    }
  }
}
</script>
