import axiosIns from '@libs/axios'

export const state = {
  countries: [],
  bookingListLoading: false,
  bookingList: [],
  bookingHistoryLoading: false,
  bookingHistory: [],
  bookingDetailLoading: false,
  bookingDetail: null
}

export const getters = {
  fethedCountries(state) {
    return state.countries
  }
}

export const mutations = {
  SET_COUNTRIES(state, value) {
    state.countries = value
  },
  SET_BOOKING_LIST_LOADING(state, value) {
    state.bookingListLoading = value
  },
  SET_BOOKING_LIST(state, value) {
    state.bookingList = value
  },
  SET_BOOKING_HISTORY_LOADING(state, value) {
    state.bookingHistoryLoading = value
  },
  SET_BOOKING_HISTORY(state, value) {
    state.bookingHistory = value
  },
  SET_BOOKING_DETAIL_LOADING(state, value) {
    state.bookingDetailLoading = value
  },
  SET_BOOKING_DETAIL(state, value) {
    state.bookingDetail = value
  },
}

export const actions = {
  fetchCountries({ commit, state }, ...args) {
    // 2. Check if we've already fetched and cached the user.
    const fethedCountries = state.countries
    if (fethedCountries.length !== 0) {
      return Promise.resolve(fethedCountries)
    }

    // 3. Fetch the user from the API and cache it in case
    //    we need it again in the future.
    return axiosIns.post('/data/country', ...args).then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_COUNTRIES', data)
        return data
      }

      return null
    })
  },

  updateProfile({commit, state, rootState}, ...args) {
    const { currentUser } = rootState.auth
    return axiosIns.put(`user/${currentUser.id}/update`, ...args).then((response) => {
      const { success, data } = response.data
      return success
    }).catch((error) => {
      console.warn(error)
      return false
    });
  },

  fetchBookingList({ commit, state }) {
    commit('SET_BOOKING_LIST_LOADING', true)
    return axiosIns.get('/getBookingList').then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_BOOKING_LIST', data)
        commit('SET_BOOKING_LIST_LOADING', false)
        return data
      }

      return null
    })
  },

  fetchBookingHistory({ commit, state }) {
    commit('SET_BOOKING_HISTORY_LOADING', true)
    return axiosIns.get('/getBookingListHistory').then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_BOOKING_HISTORY', data)
        commit('SET_BOOKING_HISTORY_LOADING', false)
        return data
      }

      return null
    })
  },

  fetchBookingDetail({ commit, state }, bookingId) {
    commit('SET_BOOKING_DETAIL_LOADING', true)
    return axiosIns.get(`/getBookingDetail/${bookingId}`).then((response) => {
      const { success, data } = response.data
      if (success) {
        commit('SET_BOOKING_DETAIL', data)
        commit('SET_BOOKING_DETAIL_LOADING', false)
        return data
      }

      return null
    })
  },

  cancelBooking({ commit, state }, ...args) {
    return axiosIns.post('cancelBooking', ...args).then((response) => {
      const { success, message } = response.data
      if (success) {
        return message
      }

      return null
    })
  },
}
