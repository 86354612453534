<template>
  <!-- Footer Start -->
  <footer class="footer bg-[#040707] relative text-gray-200">
    <div class="container">
      <div class="w-full grid grid-cols-12">
        <div class="col-span-12">
          <div class="py-[60px] px-0">
            <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px] place-items-center md:place-items-start text-center md:text-left">
              <div class="lg:col-span-6 md:col-span-12">
                <h4 class="font-semibold lg:leading-none leading-none text-4xl lg:text-7xl mb-6 text-white">It’s the <br><span class="text-theme-orange">Levels</span> Way</h4>

                <ul class="list-none mb-6">
                  <li class="block md:inline"><a href="tel:123-456-7890" class="mr-5 text-base font-normal text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><BaseIcon name="phone-alt" class="mr-1" /> 07903565785</a></li>
                  <li class="block md:inline"><a href="mailto:info@levelsabovetherest.com" class="mr-5 text-base font-normal text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><BaseIcon name="envelope" class="mr-1" /> info@levelsabovetherest.com</a></li>
                </ul>

                <BaseLink tag='a' :to="{ name: 'choose-programs' }" exac class="btn bg-theme-orange hover:bg-theme-orange/95 border-theme-orange hover:border-theme-orange/95 text-white rounded-md shadow-md shadow-theme-orange/50 group" :class="{ 'disabled' : isLoadingValidateUser }">
                  Book Session Now
                </BaseLink>
              </div>
              <!--end col-->

              <div class="lg:col-span-2 md:col-span-12">
                <h5 class="tracking-[1px] text-gray-100 font-semibold">LATR</h5>
                <ul class="list-none footer-list mt-6">
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">About</BaseLink></li>
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Contact</BaseLink></li>
                </ul>
              </div>
              <!--end col-->

              <div class="lg:col-span-2 md:col-span-12">
                <h5 class="tracking-[1px] text-gray-100 font-semibold">Story</h5>
                <ul class="list-none footer-list mt-6">
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Success Stories</BaseLink></li>
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">News and Signings</BaseLink></li>
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Gallery</BaseLink></li>
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Levels FC</BaseLink></li>
                </ul>
              </div>
              <!--end col-->

              <div class="lg:col-span-2 md:col-span-12">
                <h5 class="tracking-[1px] text-gray-100 font-semibold">Account</h5>
                <ul class="list-none footer-list mt-6">
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Cart</BaseLink></li>
                  <li class="mb-[10px]"><BaseLink tag='a' :to="{ name: 'home' }" exac class="text-gray-300 text-base font-normal hover:text-gray-400 duration-500 ease-in-out">Digital Downloads</BaseLink></li>
                </ul>
              </div>
              <!--end col-->
            </div>
            <!--end grid-->
          </div>
          <!--end col-->
        </div>
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </footer>
  <!--end footer-->
  <!-- Footer End -->
</template>

<script>
import { authComputed } from '@state/helpers'

export default {
  computed: {
    ...authComputed,
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
