<template>
  <section class="h-screen flex items-center justify-center bg-[#F3F7FA] bg-[url('@assets/images/bg/bg-01.jpeg')] bg-cover bg-no-repeat bg-center">
    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
    <div class="container-fluid relative">
      <div class="grid grid-cols-1">
        <div class="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
          <div class="text-center">
            <a href="index.html"><img src="@assets/images/logo-latr.png" class="mx-auto" alt="" /></a>
          </div>
          <div class="title-heading text-center my-auto">
            <img src="@assets/images/404.svg" class="mx-auto" alt="" />
            <h1 class="mt-3 mb-6 md:text-5xl text-3xl font-bold text-theme-orange">Page Not Found</h1>
            <p class="text-slate-300">
              Looks like the page you were looking for wasn't found. <br/>
              But dont worry, you can find plenty of other things on our Home page.
            </p>

            <div class="mt-4">
              <BaseLink tag='a' :to="{ name: 'home' }" class="btn bg-theme-orange hover:bg-theme-orange/75 border-theme-orange hover:border-theme-orange/75 text-white rounded-md shadow-md shadow-theme-orange/50">Back to Home</BaseLink>
            </div>
          </div>
          <div class="text-center">
            <p class="mb-0 text-slate-300">
              © {{ year }} {{ appName }}
            </p>
          </div>
        </div>
      </div>
      <!--end grid-->
    </div>
  </section>
</template>

<script>
import appConfig from '@src/app.config'

export default {
  page: {
    title: '404 Not Found',
    meta: [{ name: 'description', content: `Looks like the page you were looking for wasn't found.` }],
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      appName: appConfig.title,
      year: new Date().getFullYear()
    }
  }
}
</script>
