<template>
  <header class="navbar bg-white shadow is-sticky px-4 md:px-10 2xl:px-24 relative">
    <div class="navbar-start">
      <RouterLink to="/">
        <img src="@assets/images/logo-latr-text.png" class="h-9 mr-5 hidden lg:inline-block" alt="" />
        <img src="@assets/images/logo-levels-fc-text.png" class="h-9 mr-5 hidden lg:inline-block" alt="" />

        <img src="@assets/images/logo-latr.png" class="h-9 mr-2 inline-block lg:hidden" alt="" />
        <img src="@assets/images/logo-levels-fc.png" class="h-9 mr-2 inline-block lg:hidden" alt="" />
      </RouterLink>
    </div>

    <div class="navbar-end">
      <div id="navigation">
        <ul class="navigation-menu menu menu-horizontal compact p-0">
          <NavBarRoutes :routes="persistentNavRoutes" />
        </ul>
      </div>

      <BaseLink v-if="loggedIn" tag='a' :to="{ name: 'index-profile' }" class="flex ml-5" :class="{ 'cursor-not-allowed pointer-events-none' : isLoadingValidateUser }">
        <div class="avatar">
          <div class="w-10 rounded-full">
            <img v-lazy="currentUser.member.profile_pic_url" :alt="currentUser.member.fullname" class="w-10 object-cover object-center lazy-img-fadein lazy-image" />
          </div>
        </div>
      </BaseLink>

      <BaseLink v-if="!loggedIn" tag='a' :to="{ name: 'login' }" class="btn rounded-lg ml-5 bg-theme-orange/5 hover:bg-theme-orange border-theme-orange/10 hover:border-theme-orange text-theme-orange hover:text-white">
        Login
      </BaseLink>

      <BaseLink v-if="loggedIn" tag='a' :to="{ name: 'review' }" class="btn rounded-lg ml-3 bg-theme-orange/5 hover:bg-theme-orange border-theme-orange/10 hover:border-theme-orange text-theme-orange hover:text-white group hidden md:inline-flex" :class="{ 'disabled' : isLoadingValidateUser }">
        <feather-icon icon="ShoppingCartIcon" size="20" class="mr-2" />
        <div class="badge badge-theme-orange text-white group-hover:bg-white group-hover:border-white group-hover:text-theme-orange">
          {{ bookingFormData.total_ticket }}
        </div>
      </BaseLink>

      <BaseLink tag='a' :to="{ name: 'choose-programs' }" class="btn rounded-lg ml-3 bg-theme-orange hover:bg-theme-orange/75 border-theme-orange hover:border-theme-orange/75 text-white" :class="{ 'disabled' : isLoadingValidateUser }">
        Book Now
      </BaseLink>

      <!-- Mobile menu toggle-->
      <div class="menu-extras">
        <div class="menu-item">
          <a class="navbar-toggle" id="isToggle" @click.prevent="toggleMenu()">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>
      <!-- End mobile menu toggle-->

    </div>
  </header>
</template>

<script>
import { authComputed, latrComputed } from '@state/helpers'
import NavBarRoutes from './nav-bar-routes.vue'

export default {
  components: { NavBarRoutes },
  data() {
    return {
      persistentNavRoutes: [
        {
          name: 'home',
          title: 'Home',
        },
        {
          name: 'latr-levels-fc',
          title: 'LATR & Levels FC',
        },
        {
          name: 'signings',
          title: 'Signings',
        },
        {
          name: 'schedule-coaches',
          title: 'Training Schedule & Coaches',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...latrComputed,
  },
  watch: {
    '$route' (to, from) {
      this.closeMenu();
    }
  },
  methods: {
    closeMenu() {
      document.getElementById('isToggle').classList.remove('open');
      var isOpen = document.getElementById('navigation')
      isOpen.style.display = "none";
      isOpen.childNodes[0].classList.add("menu-horizontal");
    },
    toggleMenu() {
      document.getElementById('isToggle').classList.toggle('open');
      var isOpen = document.getElementById('navigation')
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
        isOpen.childNodes[0].classList.add("menu-horizontal");
      } else {
        isOpen.style.display = "block";
        isOpen.childNodes[0].classList.remove("menu-horizontal");
      }
    }
  },
}
</script>
